import React, { useEffect } from "react";
import { graphql } from "gatsby";
import "bootstrap/dist/css/bootstrap.min.css";
import { Layout } from "../components/Layout";
import { NewsContentMain } from "../components/NewsContentMain";
import { SubscribeBlock } from "../components/SubscribeBlock";
import { NewsReadAlsoBlock } from "../components/NewsReadAlsoBlock";
import { updateHeaderStyle } from "../store/actions/base";
import { useDispatch } from "react-redux";

export default ({ data }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    updateHeaderStyle(dispatch, false);
  }, []);

  return (
    <Layout data={data.wordpressPost}>
      <NewsContentMain data={data.wordpressPost} />
      <SubscribeBlock />
      <NewsReadAlsoBlock id={data.wordpressPost.wordpress_id} />
    </Layout>
  );
};

export const postQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      wordpress_id
      type
      title
      content
      yoast {
        title
        metadesc
      }
      featured_media {
        localFile {
          publicURL
        }
      }
      acf {
        nsc_image_desc
        nsc_video_iframe
        nsc_content
      }
      polylang_current_lang
      polylang_translations {
        polylang_current_lang
        id
        path
        slug
        status
      }
    }
  }
`;
