import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Link } from "gatsby";
import { translator } from "../../../helpers/lang";

export const Crumbs = () => {
  const dataBase = useSelector(state => state.base);
  const langCurrent = dataBase.lang.current;

  return (
    <StyledCrumbs>
      <li>
        <Link
          to={translator(langCurrent, {
            ru: "/",
            en: "/en",
            kz: "/kz",
          })}
          dangerouslySetInnerHTML={{
            __html: translator(langCurrent, {
              ru: "Главная",
              en: "Home",
              kz: "Басты бет",
            }),
          }}
        />
      </li>
      <li>
        <Link
          to={translator(langCurrent, {
            ru: "/media",
            en: "/en/media-en/",
            kz: "kz/media-kz/",
          })}
          dangerouslySetInnerHTML={{
            __html: translator(langCurrent, {
              ru: "Пресс-центр",
              en: "Press center",
              kz: "Баспасөз орталығы",
            }),
          }}
        />
      </li>
    </StyledCrumbs>
  );
};

const StyledCrumbs = styled.ul`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  list-style-type: none;
  padding: 0;
  margin: 0 0 10px;
  li {
    &:nth-child(n + 2) {
      &:before {
        content: "—";
        margin: 0 10px;
      }
    }
    a {
      color: #898989;
    }
    .child {
      color: #898989;
      display: inline-block;
    }
  }
`;
