import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { useNewsList } from "../../hooks/use-news-list";
import { translator } from "../../helpers/lang";

import { BREAKPOINTS } from "../../settings";

export const NewsReadAlsoBlock = ({ id }) => {
  const dataBase = useSelector(state => state.base);
  const langCurrent = dataBase.lang.current;

  const news_list = useNewsList(langCurrent, id);
  const sliced_news = news_list.slice(0, 2);

  return (
    <>
      {sliced_news && (
        <StyledNewsReadAlsoBlock className="block-container">
          <div className="block_title">
            <h2
              dangerouslySetInnerHTML={{
                __html: translator(langCurrent, {
                  ru: "Читайте также",
                  en: "Read also",
                  kz: "Сондай-ақ оқыңыз",
                }),
              }}
            />
          </div>

          <div className="news_list">
            {sliced_news.map((news, index) => (
              <div key={index} className="news_item">
                <Link
                  className="title"
                  to={news.path.replace("/wp/", "/")}
                  dangerouslySetInnerHTML={{ __html: news.title }}
                />

                <div className="info_line">
                  <div
                    className="date"
                    dangerouslySetInnerHTML={{
                      __html: news.date,
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </StyledNewsReadAlsoBlock>
      )}
    </>
  );
};

const StyledNewsReadAlsoBlock = styled.div`
  .block_title {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #b6bec0;
    margin-bottom: 15px;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 0;
    }
    h2 {
      font-weight: bold;
      font-size: 30px;
      line-height: 40px;
      color: #040404;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        font-size: 30px;
        line-height: 45px;
      }
    }
  }

  .news_list {
    width: 100%;
    height: auto;
    margin-left: auto;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      width: 75%;
    }
    .news_item {
      width: 100%;
      height: auto;
      padding: 25px 0;
      border-bottom: 1px solid #b6bec0;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        padding: 30px 0;
      }
      .title {
        font-size: 18px;
        line-height: 25px;
        color: #040404;
        margin-bottom: 5px;
        &:hover {
          text-decoration: none;
          color: #0083e5;
        }
        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
          font-size: 25px;
          line-height: 35px;
          margin-bottom: 10px;
        }
      }
      .info_line {
        width: 100%;
        height: auto;
        font-size: 15px;
        line-height: 20px;
        color: #898989;
        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
          font-size: 14px;
        }
        .category {
          display: inline-block;
          margin-right: 15px;
          color: #040404;
          @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
            color: #898989;
          }
        }
        .date {
          display: inline-block;
        }
      }
    }
  }
`;
