// libraries
import React from "react";
import styled from "styled-components";
// components
import { Crumbs } from "./Crumbs";
// constants
import { BREAKPOINTS } from "../../settings";

export const NewsContentMain = ({ data }) => {
  return (
    <>
      {data && (
        <StyledNewsContentMain
          className="block-container custom_container"
        >
          <Crumbs />

          <h1
            dangerouslySetInnerHTML={{
              __html: data.title,
            }}
          />

          {data.featured_media && data.featured_media.localFile.publicURL && (
            <div className="image_block">
              <img 
                class="img"
                src={data.featured_media.localFile.publicURL}
                alt={data.title}
              />
              {data.acf.nsc_image_desc && (
                <div
                  className="img_title"
                  dangerouslySetInnerHTML={{
                    __html: data.acf.nsc_image_desc,
                  }}
                />
              )}
            </div>
          )}

          {data.acf && data.acf.nsc_video_iframe && (
            <div
              className="video_block"
              dangerouslySetInnerHTML={{
                __html: data.acf.nsc_video_iframe,
              }}
            />
          )}

          {data.acf && data.acf.nsc_content && (
            <div
              className="top_content_preview"
              dangerouslySetInnerHTML={{
                __html: data.acf.nsc_content,
              }}
            />
          )}

          {data.content && (
            <div
              className="the_content"
              dangerouslySetInnerHTML={{
                __html: data.content,
              }}
            />
          )}
        </StyledNewsContentMain>
      )}
    </>
  );
};

const StyledNewsContentMain = styled.div`
  &.custom_container {
    padding-top: 120px;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      padding-top: 180px;
    }
  }

  h1 {
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    color: #040404;
    margin-bottom: 35px;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      font-size: 50px;
      line-height: 65px;
      margin-bottom: 50px;
    }
  }

  .image_block {
    width: 100%;
    height: auto;
    margin-top: 35px;
    margin-bottom: 50px;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      margin-top: 90px;
      margin-bottom: 90px;
    }
    .img {
      display: block;
      width: auto;
      height: auto;
      max-width: 100%;
      overflow: hidden;
      border-radius: 7px;
    }
    .img_title {
      font-size: 16px;
      line-height: 25px;
      color: #898989;
      margin-top: 15px;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        font-size: 15px;
        line-height: 20px;
      }
    }
  }

  .video_block {
    width: 100%;
    height: auto;
    margin-top: 35px;
    margin-bottom: 50px;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      margin-top: 90px;
      margin-bottom: 90px;
    }
    iframe {
      width: 100%;
      height: 280px;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        height: 420px;
      }
    }
  }

  .top_content_preview {
    width: 100%;
    height: auto;
    font-size: 20px;
    line-height: 30px;
    color: #040404;
    margin-top: 35px;
    margin-bottom: 35px;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      margin-top: 90px;
      margin-bottom: 50px;
      font-size: 30px;
      line-height: 45px;
    }
  }

  .the_content {
    width: 100%;
    font-size: 15px;
    line-height: 25px;
    color: #040404;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      font-size: 20px;
      line-height: 35px;
    }
    p {
      margin-bottom: 25px;
      &:last-child {
        margin-bottom: 0;
      }
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        margin-bottom: 15px;
      }
    }
    .img {
      width: 100%;
      height: 240px;
      margin-bottom: 35px;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        margin-bottom: 50px;
        height: 380px;
      }
    }
    h2 {
      font-weight: bold;
      font-size: 25px;
      line-height: 30px;
      color: #040404;
      margin-bottom: 30px;
      margin-top: 50px;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        font-size: 30px;
        line-height: 40px;
      }
    }

    iframe {
      width: 100%;
      height: auto;
      aspect-ratio: 16 / 9;
    }

    blockquote {
      display: block;
      width: 100%;
      height: auto;
      padding-left: 20px;
      border-left: 3px solid #0083e5;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        padding-left: 30px;
      }
      .quote_author {
        display: block;
        width: 100%;
        height: auto;
        min-height: 60px;
        position: relative;
        padding-left: 80px;
        img {
          display: block;
          width: 60px !important;
          height: 60px !important;
          border-radius: 50%;
          position: absolute;
          top: 0;
          left: 0;
          margin: 0;
          padding: 0;
        }
        .name {
          display: block;
          font-weight: bold;
          font-size: 15px;
          line-height: 20px;
          color: #040404;
          width: 100%;
          @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
            font-size: 20px;
            line-height: 30px;
          }
        }
        .position {
          display: block;
          width: 100%;
          font-size: 15px;
          line-height: 20px;
          color: #898989;
          @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
            font-size: 16px;
            line-height: 25px;
          }
        }
      }
    }
  }
`;
